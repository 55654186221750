<!--
Basic component that receives a process uuid and periodically polls the rest
service for its progress.
The default slot is scoped and receives the following data:
- progress: between 0 and 1
- current: int
- total: int
- time_elapsed: string
- time_remaining: string
- result
- error
-->
<script>
import {ITTIModelBase} from "@/ittijs/ITTIModel";

const model = new ITTIModelBase('ProgressTracker');

export default {
	props: {
		uuid: String,
		interval: { // milliseconds
			type: Number,
			default: 1000,
		},
		progress: {}, // just for sync, not used
	},
	data(){
		return {
			innerProgress: null,
			intervalHandle: null,
			logAccumulator: '',
		}
	},
	mounted() {
		this.start();
	},
	beforeDestroy() {
		this.stop();
	},
	activated() {
		this.start();
	},
	deactivated() {
		this.stop();
	},
	watch: {
		uuid(){
			this.start();
		},
		interval(){
			// only restart if already running
			if (this.intervalHandle) this.start();
		},
	},
	methods: {
		async getProgress(){
			this.innerProgress = await model.fetch('getProgress', {
				uuid: this.uuid,
				log_offset: this.innerProgress?.log_length ?? 0,
			});
			if (this.innerProgress.log != '') {
				this.logAccumulator += this.innerProgress.log;
				this.$emit('log', this.innerProgress.log);
			}
			if (this.innerProgress.error) {
				this.stop();
				this.$emit('error', this.innerProgress.error);
				this.$emit('update:progress', this.innerProgress);
				return;
			}
			this.$emit('progress', this.innerProgress);
			this.$emit('update:progress', this.innerProgress);
			if (this.innerProgress.progress === 1) {
				this.stop();
				this.$emit('ready', this.innerProgress.result);
			}
		},
		async start(){
			this.stop();
			if (!this.uuid) return;
			await this.getProgress();
			if (!this.innerProgress.error && this.innerProgress.progress < 1 && this.interval > 0) {
				this.intervalHandle = setInterval(() => this.getProgress(), this.interval);
			}
		},
		stop(){
			if (this.intervalHandle){
				clearInterval(this.intervalHandle);
				this.intervalHandle = null;
			}
		},
	},
	/**
	 * Renderless component which only returns the scoped slot "default"
	 */
	render(){
		return this.$scopedSlots.default && this.$scopedSlots.default({
			progress       : this.innerProgress && this.innerProgress.progress,
			current        : this.innerProgress && this.innerProgress.current,
			total          : this.innerProgress && this.innerProgress.total,
			time_elapsed   : this.innerProgress && this.innerProgress.time_elapsed,
			time_remaining : this.innerProgress && this.innerProgress.time_remaining,
			result         : this.innerProgress && this.innerProgress.result,
			error          : this.innerProgress && this.innerProgress.error,
			log            : this.logAccumulator,
		});
	},
}
</script>